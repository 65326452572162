import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class PatientService {
  apiUrl = environment.api
  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  constructor(private _httpClient: HttpClient) {}

  getPatients(): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/admin/patient`, {headers: this.reqHeader});
  }

  getPatientDetail(patientId: number): Observable<any>{
    return this._httpClient.get(`${this.apiUrl}/admin/patient/${patientId}`, {headers: this.reqHeader});
  }
}
