import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { PatientService } from '../service/patient.service';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { Patient } from '../model/patient.model';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PatientComponent implements OnInit {
  patients: any[];
  loading: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'name', 'phone', 'email', 'created_at'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _patientService: PatientService,
    private _matDialog: MatDialog) {
      this.dataSource = new MatTableDataSource(this.patients);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.loading = true;
    this._patientService.getPatients()
      .subscribe((data: any) => {
        this.loading = false;
        this.patients = data.patients;
        this.dataSource.data = this.patients;
      }, error => console.log(error));
  }

  onClickPatientDetail(patient: Patient): void {
    this._matDialog.open(PatientDetailComponent, {
        panelClass: 'patient-detail-dialog',
        width: '600px',
        data: {
            patientId: patient.id
        }
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPrescriptionStatus(id: any): string{
    if (id === 1) { return 'Pending'; }
    else if (id === 2) { return 'Approved'; }
    else if (id === 3) { return 'Rejected'; }
  }
}
