import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prescription } from '../model/prescription.model';
import { environment } from 'environments/environment';

@Injectable()
export class PrescriptionService {
  apiUrl = environment.api
  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  constructor(private _httpClient: HttpClient) {}

  getPrescription(): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/admin/prescription`, {headers: this.reqHeader});
  }

  getPrescriptionDetail(id: number): Observable<any>{
    return this._httpClient.get(`${this.apiUrl}/admin/prescription/${id}`, {headers: this.reqHeader});
  }

  savePrescription(prescription: Prescription): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/prescription`, prescription, {headers: this.reqHeader});
  }

  sendPrescriptionToPatient(id: number): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/prescription/${id}/patient/email`, {}, {headers: this.reqHeader});
  }
}
