import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Nurse } from '../model/nurse.model';
import { environment } from 'environments/environment';

@Injectable()
export class NurseService {
  apiUrl = environment.api;
  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  private updatedNurse = new BehaviorSubject<Nurse>(null);
  updatedNurse$ = this.updatedNurse.asObservable();
  updateNurse(nurse: Nurse): void {
    this.updatedNurse.next(nurse);
  }

  constructor(private _httpClient: HttpClient) {}

  getNurses(): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/admin/nurse`, {headers: this.reqHeader});
  }

  saveNurse(nurse: Nurse): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/nurse`, nurse, {headers: this.reqHeader});
  }
}
