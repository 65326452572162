import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Login2Service } from '../login-2/login-2.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup
    adminId: any
    resetPasswordSuccessful: boolean
    constructor(private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _loginService: Login2Service,
        private _activatedRoute: ActivatedRoute,
        private _router: Router) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit() {
        this._activatedRoute.params.subscribe((param) => {
            if(param.adminId){
                this.adminId = parseInt(param.adminId)
            } else {
                this._router.navigate(['/auth/login'])
            }
        })
        this.resetPasswordForm = this._formBuilder.group({
            password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidatorReset]]
        });

        this.resetPasswordForm.get('password').valueChanges
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    onClickReset(): void {
        const password = this.resetPasswordForm.get('password').value
        this._loginService.resetPassword(this.adminId, password)
            .subscribe((data: any) => {
                // if (data.error) {
                //     this._matSnackBar.open(data.error, null, {duration: 6000, panelClass: 'mat-snack-danger'});
                // }
                if (data.message === 'reset_password_successful'){
                    this.resetPasswordSuccessful = true
                }
            }, error => console.log(error));
    }

}

export const confirmPasswordValidatorReset: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { 'passwordsNotMatching': true };
};
