export class Nurse {
    id: number;
    nurseNo?: string;
    firstName?: string;
    lastName?: string;
    minc?: string;
    email: string;
    phone?: string;
    password?: string;
    suspended?: boolean;
    created_at: Date;
    updated_at: Date;
}