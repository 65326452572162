import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { PrescriptionService } from '../service/prescription.service';
import { PrescriptionDetailComponent } from './prescription-detail/prescription-detail.component';
import { fuseAnimations } from '@fuse/animations';
import { Prescription, PrescriptionStatus } from '../model/prescription.model';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PrescriptionComponent implements OnInit {
  prescriptions: any[];
  loading: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['patient', 'healthcareProfessional', 'status'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _prescriptionService: PrescriptionService,
    private _matDialog: MatDialog) {
      this.dataSource = new MatTableDataSource(this.prescriptions);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.loading = true;
    this._prescriptionService.getPrescription()
      .subscribe((data: any) => {
        this.loading = false;
        this.prescriptions = data.prescriptions;
        this.dataSource.data = this.prescriptions;
      }, error => console.log(error));
  }

  onClickPrescriptionDetail(prescription: Prescription): void {
    this._matDialog.open(PrescriptionDetailComponent, {
        panelClass: 'prescription-detail-dialog',
        width: '600px',
        data: {
          prescriptionId: prescription.id
        }
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPrescriptionStatus(status: any){
    if(status === PrescriptionStatus.pending) {
      return 'Pending'
    } else if(status === PrescriptionStatus.finished) {
      return 'Completed'
    }
  }
}
