export class Appointment {
    id: number;
    appointmentNo?: string;
    appointmentCreationDate?: Date;
    appointmentStatus?: number;
    appointmentDate?: Date;
    appointmentTimeSlot?: string;
    appointmentUrl?: string;
    patientId?: number;
    nurseId?: number;
    created_at: Date;
    updated_at: Date;
}

export enum AppointmentStatus {
    pending,
    completed,
    cancelled,
    missed
}