import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { CalendarService } from './calendar.service';
import { NgModule } from '@angular/core';
import { CalendarEventFormDialogComponent } from './event-form/event-form.component';
import { MatButtonModule, 
         MatDatepickerModule, 
         MatDialogModule, 
         MatFormFieldModule, 
         MatIconModule, 
         MatInputModule, 
         MatSlideToggleModule, 
         MatToolbarModule, MatTooltipModule, MatCheckboxModule, MatTabsModule, MatCardModule, MatSelectModule, MatOptionModule, MatTableModule } from '@angular/material';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


const routes: Routes = [
    {
        path     : 'calendar',
        component: CalendarComponent
    }
];

@NgModule({
    declarations   : [
        CalendarComponent,
        CalendarEventFormDialogComponent
    ],
    imports        : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatTabsModule,
        MatCardModule,
        MatSelectModule,
        MatOptionModule,
        MatTableModule,

        AngularCalendarModule.forRoot({
            provide   : DateAdapter,
            useFactory: adapterFactory
        }),
        ColorPickerModule,

        FuseSharedModule,
        FuseConfirmDialogModule
    ],
    providers      : [
        CalendarService
    ],
    entryComponents: [
        CalendarEventFormDialogComponent
    ]
})
export class CalendarModule
{
}
