import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';

import { MatColors } from '@fuse/mat-colors';

import { CalendarEventModel } from 'app/main/calendar/event.model';
import { Availability } from '../availability.model';
import { CalendarService } from '../calendar.service';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from 'app/main/e-commerce/service/appointment.service';
import { environment } from 'environments/environment';

@Component({
    selector     : 'calendar-event-form-dialog',
    templateUrl  : './event-form.component.html',
    styleUrls    : ['./event-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CalendarEventFormDialogComponent implements OnInit
{
    action: string;
    event: CalendarEvent;
    eventForm: FormGroup;
    dialogTitle: string;
    presetColors = MatColors.presets;
    nurseId: number;
    selectedDate: any;
    markAll: boolean;
    appointments: any[] = []
    showDialogAction: boolean = true
    showSaveButton: boolean
    disabledTimeSlot: boolean

    availability: Availability = new Availability();
    availabilities: any[] = []
    selectedAvailability: any = {}
    imagePlaceholderUrl: string = `${environment.api}/storage/profile.jpeg`

    constructor(public matDialogRef: MatDialogRef<CalendarEventFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _formBuilder: FormBuilder,
                private _calendarService: CalendarService,
                private _activedRoute: ActivatedRoute,
                private _matSnackBar: MatSnackBar,
                private _appointmentService: AppointmentService) {
        this.event = _data.event;
        this.action = _data.action;
        this.nurseId = _data.nurseId;
        this.selectedDate = _data.date;
        this.dialogTitle = 'Available Slots';

        if ( this.action === 'edit' )
        {
            // this.dialogTitle = this.event.title;
        }
        else
        {
            // this.dialogTitle = 'New Event';
            this.event = new CalendarEventModel({
                start: _data.date,
                end  : _data.date
            });
        }

        if(this.selectedDate) {
            const selectedDate = moment(this.selectedDate)
            const today = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
            this.showSaveButton = selectedDate.isSameOrAfter(today)
            this.disabledTimeSlot = !this.showSaveButton
        }
    }

    onSelectHP(event): void {
        const id = event.value
        this.selectedAvailability = this.availabilities.find(a => a.id === id)
        const availableSlots = JSON.parse(this.selectedAvailability.availableTimeSlot)
        
        const availableTimeSlots = this.createAllDayTimeSlots();
        Object.keys(availableTimeSlots).map((key) => availableTimeSlots[key] = availableSlots[key]);
        this.selectedAvailability.availableTimeSlot = availableTimeSlots;
    }

    
    
    ngOnInit(): void {
        this._calendarService.getAllAvailabilitiesByDate(this.selectedDate)
            .subscribe((response: any) => {
                response.forEach(day => {
                    const availableTimeSlot = JSON.parse(day.availableTimeSlot)
                    const slots = Object.keys(availableTimeSlot).filter(k => availableTimeSlot[k]);
                    day.availableSlots = slots.length
                })
                this.availabilities = response
            }, error => console.log(error));
        if(this.selectedDate) {
            this._calendarService.getAllAppointmentsByDate(this.selectedDate)
                .subscribe((data: any) => {
                    this.appointments = data
                })
        }
    }

    onTabChanged(tab: any): void {
        this.showDialogAction = tab.index === 0
    }

    private createAllDayTimeSlots(): any{
        const timeSlots = {
            '12:00 AM - 12:30 AM': false,
            '12:30 AM - 1:00 AM': false,
            '1:00 AM - 1:30 AM': false,
            '1:30 AM - 2:00 AM': false,
            '2:00 AM - 2:30 AM': false,
            '2:30 AM - 3:00 AM': false,
            '3:00 AM - 3:30 AM': false,
            '3:30 AM - 4:00 AM': false,
            '4:00 AM - 4:30 AM': false,
            '4:30 AM - 5:00 AM': false,
            '5:00 AM - 5:30 AM': false,
            '5:30 AM - 6:00 AM': false,
            '6:00 AM - 6:30 AM': false,
            '6:30 AM - 7:00 AM': false,
            '7:00 AM - 7:30 AM': false,
            '7:30 AM - 8:00 AM': false,
            '8:00 AM - 8:30 AM': false,
            '8:30 AM - 9:00 AM': false,
            '9:00 AM - 9:30 AM': false,
            '9:30 AM - 10:00 AM': false,
            '10:00 AM - 10:30 AM': false,
            '10:30 AM - 11:00 AM': false,
            '11:00 AM - 11:30 AM': false,
            '11:30 AM - 12:00 PM': false,
            '12:00 PM - 12:30 PM': false,
            '12:30 PM - 1:00 PM': false,
            '1:00 PM - 1:30 PM': false,
            '1:30 PM - 2:00 PM': false,
            '2:00 PM - 2:30 PM': false,
            '2:30 PM - 3:00 PM': false,
            '3:00 PM - 3:30 PM': false,
            '3:30 PM - 4:00 PM': false,
            '4:00 PM - 4:30 PM': false,
            '4:30 PM - 5:00 PM': false,
            '5:00 PM - 5:30 PM': false,
            '5:30 PM - 6:00 PM': false,
            '6:00 PM - 6:30 PM': false,
            '6:30 PM - 7:00 PM': false,
            '7:00 PM - 7:30 PM': false,
            '7:30 PM - 8:00 PM': false,
            '8:00 PM - 8:30 PM': false,
            '8:30 PM - 9:00 PM': false,
            '9:00 PM - 9:30 PM': false,
            '9:30 PM - 10:00 PM': false,
            '10:00 PM - 10:30 PM': false,
            '10:30 PM - 11:00 PM': false,
            '11:00 PM - 11:30 PM': false,
            '11:30 PM - 11:59 PM': false
        };
        return timeSlots;
    }

    sortTimeSlot(): any{
        return 0;
    }
}
