import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  appointmentCount: number;
  patientCount: number;
  nurseCount: number;
  prescriptionCount: number;
  loading: boolean;
  constructor(private _dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.loading = true;
    this._dashboardService.getDashboardDetails()
      .subscribe((data: any) => {
        if (data){
          this.appointmentCount = data.appointmentCount;
          this.patientCount = data.patientCount;
          this.nurseCount = data.nurseCount;
          this.prescriptionCount = data.prescriptionCount;
          this.loading = false;
        }
      });
  }

}
