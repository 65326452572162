import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { MatDialogModule, 
         MatIconModule, 
         MatToolbarModule, 
         MatButtonModule} from '@angular/material';

@NgModule({
  declarations: [ConfirmationDialogComponent, InformationDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    InformationDialogComponent
  ]
})
export class SharedModule { }
