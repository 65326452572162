import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {
  dialogTitle: string;
  dialogContent: string;
  constructor(private _dialogRef: MatDialogRef<InformationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any) {
              if (_data){
                this.dialogTitle = _data.dialogTitle;
                this.dialogContent = _data.dialogContent;
              }
  }

  ngOnInit(): void {
  }

  closeDialoag(): void {
    this._dialogRef.close();
  }

}
