import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ResetPasswordComponent } from './reset-password.component';

const routes = [
  {
      path     : 'auth/reset-password/:adminId',
      component: ResetPasswordComponent
  }
];

@NgModule({
  declarations: [
      ResetPasswordComponent
  ],
  imports     : [
      RouterModule.forChild(routes),

      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,

      FuseSharedModule,
  ]
})
export class ResetPasswordModule { }
