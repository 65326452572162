import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Login2Service } from './login-2.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector     : 'login-2',
    templateUrl  : './login-2.component.html',
    styleUrls    : ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Login2Component implements OnInit {
    
    loginForm: FormGroup;
    email: string;
    password: string;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _login2Service: Login2Service,
        private _matSnackBar: MatSnackBar,
        private _router: Router){

        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        localStorage.removeItem('token');
        localStorage.removeItem('adminId');
    }

    onSubmitLoginForm(): void {
        const data: any = {
            email: this.loginForm.controls.email.value,
            password: this.loginForm.controls.password.value
        };
        this.email = this.loginForm.controls.email.value;
        this.password = this.loginForm.controls.password.value;
        this._login2Service.login(data)
            .subscribe((result: any) => {
                if (result.error){
                    this._matSnackBar.open(result.error, null, {duration: 3000, panelClass: 'mat-snack-danger'});
                    return;
                }
                localStorage.setItem('token', result.token);
                this._router.navigate(['dashboard']);
            }, error => console.log(error));
    }
}
