import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class Login2Service {
  apiUrl = environment.api
  constructor(private _httpClient: HttpClient) { }

  login(data: any): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/auth/login`, data);
  }

  resetPassword(adminId: any, newPassword: any): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/${adminId}/auth/resetPassword`, {newPassword});
  }

  sendResetPasswordEmail(email: any): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/auth/sendResetPasswordLink`, {email});
  }
}
