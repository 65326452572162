import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PrescriptionService } from '../../service/prescription.service';
import { Prescription } from '../../model/prescription.model';
import { Nurse } from '../../model/nurse.model';
import { Patient } from '../../model/patient.model';
import { Appointment, AppointmentStatus } from '../../model/appointment.model';

@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.scss']
})
export class PrescriptionDetailComponent implements OnInit {
  dialogTitle = 'Prescription Detail';
  prescriptionId: any;
  prescription: Prescription;
  nurse: Nurse;
  patient: Patient;
  appointment: Appointment;
  loading: boolean;
  prescriptionStatusError: boolean;
  constructor(private _matDialogRef: MatDialogRef<PrescriptionDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _prescriptionService: PrescriptionService,
              private _matSnackBar: MatSnackBar) {
    this.prescriptionId = _data.prescriptionId;
  }

  ngOnInit(): void {
    this.loading = true;
    this._prescriptionService.getPrescriptionDetail(this.prescriptionId)
      .subscribe((data: any) => {
        this.prescription = data.prescription;
        this.nurse = data.nurse;
        this.patient = data.patient;
        this.appointment = data.appointment;
        this.loading = false;
      }, error => console.log(error));
  }

  // onClickSave(): void {
  //   if (this.prescription){
  //     this._prescriptionService.savePrescription(this.prescription)
  //       .subscribe((data: any) => {
  //         this.closeDialoag();
  //       });
  //   }
  // }

  private validPrescriptionStatus(): boolean {
    return this.prescription.prescriptionStatus === 2 || this.prescription.prescriptionStatus === 3;
  }

  // onClickComplete(): void {
  //   if (this.prescription){
  //     if (this.validPrescriptionStatus()) {
  //       this._prescriptionService.savePrescription(this.prescription)
  //       .subscribe((data: any) => {
  //         this._prescriptionService.sendPrescriptionToPatient(this.prescription.id)
  //           .subscribe((result: any) => {
  //             this.closeDialoag();
  //             this._matSnackBar.open('The prescription has been emailed to the patient', null, {duration: 4000});
  //           });
  //       });
  //     } else {
  //       this.prescriptionStatusError = true;
  //     }
  //   }
  // }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

  getAppointmentStatus(appointmentStatus: any): string{
    switch (appointmentStatus) {
      case AppointmentStatus.pending:
        return 'Pending';
      case AppointmentStatus.completed:
        return 'Completed';
      case AppointmentStatus.cancelled:
        return 'Cancelled';
      case AppointmentStatus.missed:
        return 'Missed';
      default:
        return '';
    }
  }

}
