import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { startOfDay, isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay } from 'angular-calendar';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';

import { CalendarService } from 'app/main/calendar/calendar.service';
import { CalendarEventModel } from 'app/main/calendar/event.model';
import { CalendarEventFormDialogComponent } from 'app/main/calendar/event-form/event-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment'

@Component({
    selector     : 'calendar',
    templateUrl  : './calendar.component.html',
    styleUrls    : ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CalendarComponent implements OnInit
{
    actions: CalendarEventAction[];
    activeDayIsOpen: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;
    events: CalendarEvent[] = [];
    refresh: Subject<any> = new Subject();
    selectedDay: any;
    view: string;
    viewDate: Date;
    nurseId: number;
    availability: any[] = []
    loading: boolean
    displayedColumns: string[] = ['date', 'availableDoctors', 'availableAppointments', 'maxSlotAvailable'];
    dataSource: any[] = []

    constructor(
        private _matDialog: MatDialog,
        private _calendarService: CalendarService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    )
    {
        // Set the defaults
        this.view = 'month';
        this.viewDate = new Date();
        this.activeDayIsOpen = true;
        this.selectedDay = {date: startOfDay(new Date())};


        this.actions = [
            {
                label  : '<i class="material-icons s-16">edit</i>',
                onClick: ({event}: { event: CalendarEvent }): void => {
                    this.editEvent('edit', event);
                }
            },
            {
                label  : '<i class="material-icons s-16">delete</i>',
                onClick: ({event}: { event: CalendarEvent }): void => {
                    this.deleteEvent(event);
                }
            }
        ];
    }

    ngOnInit(): void {
        const month = this.viewDate.getMonth() + 1
        const year = this.viewDate.getFullYear()
        this._calendarService.getAllAvailabilitiesByMonth(month, year)
            .subscribe((response) => {
                this.generateMonthlyReport(month - 1, year, response)
            })
    }

    onChangeViewDate(event: any): void {
        const date =  new Date(event)
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        this._calendarService.getAllAvailabilitiesByMonth(month, year)
            .subscribe((response) => {
                this.generateMonthlyReport(month - 1, year, response)
            })
    }

    private generateMonthlyReport(month: any, year: any, availabilities: any[]) {
        const reports = []
        const days = this.getDaysInMonth(month, year)
        this._calendarService.getAllAppointmentsByMonth(month + 1, year)
            .subscribe(response => {
                days.map(day => {
                    const availabilitiesForDay = availabilities.filter(a => a.availableDate === day)
                    const appointmentsForDay = response.filter(a => a.appointmentDate === day)
                    let maxSlotAvailable = 0
                    availabilitiesForDay.forEach(day => {
                        const availableTimeSlot = JSON.parse(day.availableTimeSlot)
                        const slots = Object.keys(availableTimeSlot).filter(k => availableTimeSlot[k]);
                        maxSlotAvailable = maxSlotAvailable + slots.length
                    })
                    reports.push({
                        date: day,
                        availableDoctors: availabilitiesForDay.length,
                        availableAppointments: appointmentsForDay.length,
                        maxSlotAvailable: maxSlotAvailable
                    })
                })
                this.dataSource = reports
            })
        
    }

    private getDaysInMonth(month, year) {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
          days.push(moment(date).format('YYYY-MM-DD'));
          date.setDate(date.getDate() + 1);
        }
        return days;
    }
    
    beforeMonthViewRender({header, body}): void
    {
        /**
         * Get the selected day
         */
        const _selectedDay = body.find((_day) => {
            return _day.date.getTime() === this.selectedDay.date.getTime();
        });

        if ( _selectedDay )
        {
            /**
             * Set selected day style
             * @type {string}
             */
            _selectedDay.cssClass = 'cal-selected';
        }

    }

    onClickDate(date: any): void
    {
        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            width: '600px',
            height: '80vh',
            data      : {
                action: 'new',
                date  : date,
                nurseId: this.nurseId
            }
        });
    }

    dayClicked(day: CalendarMonthViewDay): void
    {
        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            width: '600px',
            height: '80vh',
            data      : {
                action: 'new',
                date  : day.date,
                nurseId: this.nurseId
            }
        });
    }

    eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void
    {
        event.start = newStart;
        event.end = newEnd;
        // console.warn('Dropped or resized', event);
        this.refresh.next(true);
    }

    deleteEvent(event): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                const eventIndex = this.events.indexOf(event);
                this.events.splice(eventIndex, 1);
                this.refresh.next(true);
            }
            this.confirmDialogRef = null;
        });

    }

    editEvent(action: string, event: CalendarEvent): void
    {
        const eventIndex = this.events.indexOf(event);

        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            data      : {
                event : event,
                action: action
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    /**
                     * Save
                     */
                    case 'save':

                        this.events[eventIndex] = Object.assign(this.events[eventIndex], formData.getRawValue());
                        this.refresh.next(true);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteEvent(event);

                        break;
                }
            });
    }

    addEvent(): void
    {
        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            width: '600px',
            data      : {
                action: 'new',
                date  : this.selectedDay.date,
                nurseId: this.nurseId
            }
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {});
    }
}


