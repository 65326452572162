import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Nurse } from '../../model/nurse.model';
import { NurseService } from '../../service/nurse.service';

@Component({
  selector: 'app-nurse-detail',
  templateUrl: './nurse-detail.component.html',
  styleUrls: ['./nurse-detail.component.scss']
})
export class NurseDetailComponent implements OnInit {
  dialogTitle = 'Healthcare Professional Detail';
  nurse: any;
  constructor(private _matDialogRef: MatDialogRef<NurseDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _nurseService: NurseService) {
    this.nurse = _data.nurse || new Nurse();
  }

  ngOnInit(): void {
   // console.log(this.patient);
  }

  closeDialoag(message: string): void {
    this._matDialogRef.close(message);
  }

  onClickSave(): void {
    this._nurseService.saveNurse(this.nurse)
      .subscribe((data: any) => {
        this.closeDialoag('nurseSaved');
      }, error => console.log(error));
  }

  formValid(): boolean {
    return this.nurse.email && this.nurse.password && this.nurse.firstName && this.nurse.lastName && this.nurse.minc
  }
}
