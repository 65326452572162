import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    MatButtonModule, MatChipsModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatRippleModule, MatSelectModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule, MatTabsModule, MatCard, MatCardModule, MatDialogModule, MatListModule, MatToolbarModule, MatCheckboxModule, MatRadioModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { NurseComponent } from './nurse/nurse.component';
import { PatientComponent } from './patient/patient.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrescriptionDetailComponent } from './prescription/prescription-detail/prescription-detail.component';
import { AppointmentDetailComponent } from './appointment/appointment-detail/appointment-detail.component';
import { PatientDetailComponent } from './patient/patient-detail/patient-detail.component';
import { NurseDetailComponent } from './nurse/nurse-detail/nurse-detail.component';
import { PatientService } from './service/patient.service';
import { NurseService } from './service/nurse.service';
import { AppointmentService } from './service/appointment.service';
import { PrescriptionService } from './service/prescription.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './service/dashboard.service';

const routes: Routes = [
    {
        path     : 'healthcare-professionals',
        component: NurseComponent
    },
    {
        path     : 'patients',
        component: PatientComponent
    },
    {
        path     : 'appointments',
        component: AppointmentComponent
    },
    {
        path     : 'prescriptions',
        component: PrescriptionComponent
    },
    {
        path     : 'dashboard',
        component: DashboardComponent
    },
];

@NgModule({
    declarations: [
    NurseComponent,
    PatientComponent,
    AppointmentComponent,
    PrescriptionComponent,
    PrescriptionDetailComponent,
    AppointmentDetailComponent,
    PatientDetailComponent,
    NurseDetailComponent,
    DashboardComponent],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseWidgetModule,
        MatCardModule,
        MatDialogModule,
        MatListModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatTabsModule,
        MatRadioModule
    ],
    providers   : [
        NurseService,
        PatientService,
        AppointmentService,
        PrescriptionService,
        DashboardService
    ],
    entryComponents: [
        NurseDetailComponent,
        PatientDetailComponent,
        AppointmentDetailComponent,
        PrescriptionDetailComponent
    ]
})
export class EcommerceModule
{
}
