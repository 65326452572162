import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NurseService } from '../service/nurse.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { NurseDetailComponent } from './nurse-detail/nurse-detail.component';
import { fuseAnimations } from '@fuse/animations';
import { Nurse } from '../model/nurse.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-nurse',
  templateUrl: './nurse.component.html',
  styleUrls: ['./nurse.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class NurseComponent implements OnInit {
  nurses: any[];
  loading: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'nurseNo', 'name', 'status'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _nurseService: NurseService,
    private _matDialog: MatDialog) {
      this.dataSource = new MatTableDataSource(this.nurses);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.loadNurses()
  }

  loadNurses(): void {
    this.loading = true;
    this._nurseService.getNurses()
      .subscribe((data: any) => {
        this.loading = false;
        this.nurses = data.nurses;
        this.dataSource.data = this.nurses;
      }, error => console.log(error));
  }

  onClickNurseDetail(nurse: any): void {
    let selectedNurse: Nurse = new Nurse();
    selectedNurse = _.clone(nurse);
    const nurseDialog = this._matDialog.open(NurseDetailComponent, {
        panelClass: 'nurse-detail-dialog',
        width: '600px',
        data: {
          nurse: selectedNurse
        }
    });
    nurseDialog.afterClosed().subscribe(data => {
      if(data) {
        this.loadNurses()
      }
    })
  }

  onClickCreateNurse(): void {
    const nurseDialog = this._matDialog.open(NurseDetailComponent, {
      panelClass: 'nurse-detail-dialog',
      width: '600px',
      data: {
        nurse: null
      }
    });
    nurseDialog.afterClosed().subscribe(data => {
      if(data) {
        this.loadNurses()
      }
    })
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPrescriptionStatus(id: any): string{
    if (id === 1) { return 'Pending'; }
    else if (id === 2) { return 'Approved'; }
    else if (id === 3) { return 'Rejected'; }
  }
}
