import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { Appointment, AppointmentStatus } from '../../model/appointment.model';
import { AppointmentService } from '../../service/appointment.service';
import { Patient } from '../../model/patient.model';
import { Nurse } from '../../model/nurse.model';
import { ConfirmationDialogComponent } from 'app/main/shared/confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment'

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent implements OnInit {
  dialogTitle = 'Appointment Detail';
  appointment: Appointment;
  nurse: Nurse;
  patient: Patient;
  appointmentId: number;
  loading: boolean;
  constructor(private _matDialogRef: MatDialogRef<AppointmentDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _matDialog: MatDialog,
              private _matSnackBar: MatSnackBar,
              private _appointmentService: AppointmentService) {
    this.appointmentId = _data.appointmentId;
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.appointmentId){
      this._appointmentService.getAppointmentDetail(this.appointmentId)
        .subscribe((data: any) => {
          this.appointment = data.appointment;
          this.nurse = data.nurse;
          this.patient = data.patient;
          this.loading = false;
        });
    }
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

  getAppointmentStatus(appointmentStatus: any): string{
    switch (appointmentStatus) {
      case AppointmentStatus.pending:
        return 'Pending';
      case AppointmentStatus.completed:
        return 'Completed';
      case AppointmentStatus.cancelled:
        return 'Cancelled';
      case AppointmentStatus.missed:
        return 'Missed';
      default:
        return '';
    }
  }

  onClickCancelAppointment(): void {
    const confirmationDialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        message: 'Are you sure you want to cancel the appointment ? Both patient and provider will get an email regarding this cancellation. Once you cancel an appointment, you can\'t undo this operation.'
      }
    });
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result){
        this.appointment.appointmentStatus = AppointmentStatus.cancelled
        this._appointmentService.updateAppointment(this.appointment)
          .subscribe((data: any) => {
            this._matSnackBar.open('The appointment has been successfully cancelled', null, {duration: 5000});
            this._appointmentService.sendAppointmentCancelEmail(this.appointment.id).subscribe()
            this.closeDialoag()
          });
      }
    });
  }

  showCancelAppointmentButton(): boolean {
    return this.appointment.appointmentStatus === AppointmentStatus.pending && this.shouldShowJoinCallButton(this.appointment)
  }


  shouldShowJoinCallButton(appointment: Appointment): boolean {
    const appointmentDate = moment(appointment.appointmentDate).format('YYYY-MM-DD')
    const slot = appointment.appointmentTimeSlot
    if(slot){
      const format = 'YYYY-MM-DD hh:mm a'
      const startTimeOfSlot = slot.split(' - ')[0]
      const appointmentDateTime = appointmentDate + ' ' + startTimeOfSlot
      const startTime = moment(appointmentDateTime, format)

      const currentTime = moment()
      return currentTime.isBefore(startTime)
    }
  }
}
